import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/lj/workspace/bold/site/src/components/mdx/MDXPageLayout";
import { TabLinks } from '../../../components/TabLinks';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "data-visualization",
      "style": {
        "position": "relative"
      }
    }}>{`Data visualization`}</h1>
    <p>{`Data visualization consists of presenting information and data graphically to synthesizing complex information and understanding patterns and exceptions presented in the data. Data visualization should not be used as decoration.`}</p>
    <TabLinks items={[{
      title: 'Models',
      href: '/design-guidelines/data-visualization/models'
    }, {
      title: 'Usage',
      href: '/design-guidelines/data-visualization/usage'
    }]} mdxType="TabLinks" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      